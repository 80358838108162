import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  AppInfo,
  AppName,
  Calendar,
  CalendarContainer,
  Cancel,
  Control,
  FormContainer,
  Input,
  InputGroup,
  Label,
  Logo,
  MainContainer,
  MonthContainer,
  MonthControl,
  Note,
  ReleaseDate,
  Send,
  SendControls,
  TextInputs,
  TimeLeft,
} from "./styles";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  addMonths,
  differenceInSeconds,
  parse,
} from "date-fns";
import Day from "./Day";

const Miatec = () => {
  const [isBooking, setIsBooking] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  //NOTE ------------------------------------------------------- TIMER

  const launchDate = parse("09/30/2024", "MM/dd/yyyy", new Date());
  const secondsBetweenNowAndLaunch = differenceInSeconds(
    launchDate,
    new Date()
  );

  const [seconds, setSeconds] = useState(secondsBetweenNowAndLaunch);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTimeToString = (time: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }): string => {
    const { days, hours, minutes, seconds } = time;
    return `${days} : ${hours} : ${minutes} : ${seconds}`;
  };

  const formatSeconds = (
    totalSeconds: number
  ): { days: number; hours: number; minutes: number; seconds: number } => {
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return { days, hours, minutes, seconds };
  };

  const formattedTime = formatSeconds(seconds);
  const formattedTimeString = formatTimeToString(formattedTime);
  
  //NOTE ------------------------------------------------------- CALENDAR LOGIC

  const [monthAdjust, setMonthAdjust] = useState(0);

  const month = new Date().getMonth() + monthAdjust;

  let date = new Date();
  date = addMonths(date, monthAdjust);
  const monthName = format(date, "MMMM");
  const year = new Date().getFullYear();

  const start = startOfWeek(startOfMonth(new Date(year, month)));
  const end = endOfWeek(endOfMonth(new Date(year, month)));

  const days = eachDayOfInterval({ start, end });

  const renderCalendar = () => {
    return days.map((dayInfo) => {
      return (
        <Day
          key={`${dayInfo}`}
          date={`${dayInfo.getDate()}`}
          isBooking={isBooking}
        />
      );
    });
  };

  //NOTE ------------------------------------------------------- CALENDAR LOGIC

  let templateParams = {
    name,
    email,
    phone,
  };

  const serviceKey = process.env.REACT_APP_SERVICE_KEY || "";
  const templateKey = process.env.REACT_APP_TEMPLATE_KEY || "";
  const publicKey = process.env.REACT_APP_PUBLIC_KEY || "";

  const sendEmail = () => {
    emailjs.send(serviceKey, templateKey, templateParams, publicKey).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (error) => {
        console.log("FAILED...", error);
      }
    );
  };

  //NOTE G1 ------------------------------------------------------- TIMER AND DATE

  const AppInfo_COM = () => {
    return (
      <AppInfo>
        <AppName>
          <span>SNOWARE</span>
        </AppName>
        <ReleaseDate>
          <span>9/30/2024</span>
        </ReleaseDate>
        <TimeLeft>
          <span>{formattedTimeString}</span>
        </TimeLeft>
      </AppInfo>
    );
  };

  //NOTE G2 ------------------------------------------------------- NON CALENDAR FORM ELEMENTS

  const TextInputEls = () => {
    return (
      <TextInputs isBooking={isBooking}>
        <Note>
          <span>JUST A FEW THINGS.</span>
        </Note>
        <InputGroup>
          <Label>
            <span>FULL NAME :</span>
          </Label>
          <Input onChange={(e) => setName(e.target.value)} value={name} />
        </InputGroup>
        <InputGroup>
          <Label>
            <span>EMAIL :</span>
          </Label>
          <Input onChange={(e) => setEmail(e.target.value)} value={email} />
        </InputGroup>
        <InputGroup>
          <Label>
            <span>PHONE :</span>
          </Label>
          <Input onChange={(e) => setPhone(e.target.value)} value={phone} />
        </InputGroup>
      </TextInputs>
    );
  };

  //NOTE G3 ------------------------------------------------------- CALENDAR

  const Calendar_COM = () => {
    return (
      <CalendarContainer>
        <MonthControl isBooking={isBooking}>
          <Control onClick={() => setMonthAdjust(monthAdjust - 1)}>
            <span>{"<"}</span>
          </Control>
          <MonthContainer>
            <span>{monthName.toUpperCase()}</span>
          </MonthContainer>
          <Control onClick={() => setMonthAdjust(monthAdjust + 1)}>
            <span>{">"}</span>
          </Control>
        </MonthControl>
        <Calendar isBooking={isBooking}>{renderCalendar()}</Calendar>
      </CalendarContainer>
    );
  };

  //NOTE G4 ------------------------------------------------------- SEND CONTROLS

  const SendControls_COM = () => {
    return (
      <SendControls>
        <Cancel onClick={sendEmail}>
          <span>X</span>
        </Cancel>
        <Send onClick={() => setIsBooking(!isBooking)}>
          <span>{`SEND REQUEST -->`}</span>
        </Send>
      </SendControls>
    );
  };

  return (
    <MainContainer>
      <Logo>MIATEC</Logo>
      <FormContainer>
        {AppInfo_COM()}
        {TextInputEls()}
        {Calendar_COM()}
        {SendControls_COM()}
      </FormContainer>
    </MainContainer>
  );
};

export default Miatec;
