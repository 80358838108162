import styled from "styled-components";

const centerItems = () => {
  return `
        display: flex;
        justify-content: center;
        align-items: center;
        `;
};

export const MainContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 32px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;


  @media (max-width: 400px) {
    padding: 16px;
    justify-content: flex-start;
  }

  input:focus {
    outline: none;
  }
`;

export const Logo = styled.span`
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 24px;
`;

export const FormContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  border: 1px solid white;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

//NOTE G1 ------------------------------------------------------- TIMER AND DATE

export const AppInfo = styled.div`
    display: grid;
    width: 100%
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    grid-auto-rows: minmax(min-content, max-content);
`;

export const AppName = styled.div`
  ${centerItems()}
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  height: 56px;
  background: white;
  color: black;
  cursor: pointer;
  transition: 0.3s ease;
`;

export const ReleaseDate = styled.div`
  ${centerItems()}
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  height: 56px;
`;

export const TimeLeft = styled.div`
  ${centerItems()}
  border-bottom: 1px solid white;
  grid-column: 1 / 3;
  height: 56px;
`;

//NOTE G2 ------------------------------------------------------- NON CALENDAR FORM ELEMENTS

interface TextInputsProps {
  isBooking: boolean;
}

export const TextInputs = styled.div<TextInputsProps>`
  width: 100%;
  max-height: ${(props) => (props.isBooking ? "224px" : "0px")};
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.77, 0, 0.175, 1);
`;

export const Note = styled.div`
  ${centerItems()}
  height: 56px;
  background: white;
  color: black;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const InputGroup = styled.div`
  height: 56px;
  width: 100%;
  border-bottom: 1px solid white;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr;
`;

export const Label = styled.div`
  ${centerItems()}
  height: 100%;
  font-size: 12px;
  line-height: 12px;
  padding: 0 16px;
`;

export const Input = styled.input`
  height: 100%;
  border: none;
  font-size: 14px;
  line-height: 14px;
  color: white;
  background: transparent;
`;

//NOTE G3 ------------------------------------------------------- CALENDAR

interface MonthControlProps {
  isBooking: boolean;
}

export const MonthControl = styled.div<MonthControlProps>`
  max-height: ${(props) => (props.isBooking ? "56px" : "0px")};
  opacity: ${(props) => (props.isBooking ? 1 : 0)};
  width: 100%;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr minmax(
      min-content,
      max-content
    );
  border: 1px solid white;
  border-top: none;
  z-index: 0;
  transition: max-height 0.5s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.5s cubic-bezier(0.77, 0, 0.175, 1);
`;

export const MonthContainer = styled.div`
  ${centerItems()}
  border-left: 1px solid white;
  border-right: 1px solid white;
  padding: 0 16px;
`;

export const Control = styled.div`
  ${centerItems()}
  height: 56px;
  padding: 0 16px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background: white;
    color: black;
  }
`;

export const CalendarContainer = styled.div`
  width: 100%;
`;

interface DayContainerProps {
  bgColor: string;
}

export const DayContainer = styled.div<DayContainerProps>`
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.bgColor};
  transition: background 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  color: black;
`;

interface DayNumberProps {
    isBooking: boolean;
  }

export const DayNumber = styled.span<DayNumberProps>`
    opacity: ${(props) => props.isBooking? 1 : 0};
    transition: opacity 0.5s cubic-bezier(0.77, 0, 0.175, 1);
`;

interface CalendarProps {
    isBooking: boolean;
  }


export const Calendar = styled.div<CalendarProps>`
  grid-column: 1/ 3;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: ${(props) => props.isBooking? 1 : 0}px;
  transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
`;

//NOTE G4 ------------------------------------------------------- SEND CONTROLS

export const SendControls = styled.div`
  height: 56px;
  width: 100%;
  border-top: 1px solid white;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr;
`;

export const Cancel = styled.div`
  ${centerItems()}
  height: 56px;
  aspect-ratio: 1 / 1;
  border-right: 1px solid black;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background: white;
    color: black;
  }
`;

export const Send = styled.div`
  ${centerItems()}
  height: 56px;
  background: white;
  color: black;
  cursor: pointer;
`;
