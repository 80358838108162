
import { FC } from "react";
import { DayContainer, DayNumber } from "./styles";

interface DayProps {
    date: string
    isBooking: boolean;
}

const Day:FC<DayProps> = ({date, isBooking}) => {
    let r =  Math.floor(Math.random() * 256);
    let g =  Math.floor(Math.random() * 256);
    let b =  Math.floor(Math.random() * 256);
    const bgColor = `rgb(${r},${g},${b})`;

    const WHITE = "rgb(255, 255, 255)"

  return (
    <DayContainer bgColor={isBooking ? WHITE : bgColor}>
      <DayNumber isBooking={isBooking}>{date}</DayNumber>
    </DayContainer>
  );
};

export default Day;
