import './App.css';
import Miatec from './MIATEC/Miatec';

function App() {
  return (
    <div className="App">
      <Miatec/>
    </div>
  );
}

export default App;
